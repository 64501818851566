import { BaseSyntheticEvent, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Graph from "../../../Components/Graphs/Graph";
import { fetchMarketingSpend } from "../../../Services/films/films";
import { Purify } from "../../../Components/Utils/HTMLCleaners";
import COLORS from "../../../Utils/colors";

import BarGraph from "../../../Components/Graphs/BarGraph";
import { Histogram } from "../../../Components/Graphs/Histogram";
import logger from "../../../Utils/logging";
import { MarketingData } from "./types";
import PieGraph from "./PieGraph";
import Explicit from "./Explicit";
import StatsType1 from "../../../Components/Stats/StatsType1";

export default function MarketingSpend() {
	let { filmId } = useParams();
	const [data, setData] = useState<MarketingData>();
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		async function getMarketingData() {
			const accessToken = await getAccessTokenSilently();
			const data = await fetchMarketingSpend(accessToken, filmId);
			setData(data);
		}
		getMarketingData();
	}, [getAccessTokenSilently, fetchMarketingSpend]);

	return (
		<div className="box-border flex flex-col items-center w-full h-full">
  <div className="w-4/5">
    {data && data.highlights && (
      <StatsType1 stats={data.highlights} />
    )}
  </div>

  {/* Paid Ads Spend Box */}
  <div className="p-2 border-[1px] border-solid border-light w-[80%] mb-8 mx-auto h-[50vh] flex flex-col items-center justify-center"> {/* Fixed height of 50% viewport height */}
    <h3 className="text-center">Paid Ads Spend</h3> {/* Centered title */}
    <div className="flex flex-col items-center justify-center w-full h-full">
      {data && data.PaidAds && (
        <>
          <div className="w-[30vh] h-[30vh]"> {/* Adjusted the pie chart size */}
            <PieGraph
              data={data.PaidAds}
              graphOptions={{}}
            />
          </div>

          <Explicit data={data.PaidAds} />
        </>
      )}
    </div>
  </div>

  {/* Flex container for the other two boxes */}
  <div className="flex flex-row flex-wrap w-4/5 gap-8 justify-center">
    {/* Specialty Marketing Spend Box */}
    <div className="p-3 border-[1px] border-solid border-light w-[48%] h-[50vh] flex flex-col items-center justify-center"> {/* Fixed height of 50% viewport height */}
      <h3 className="text-center">Specialty Marketing Spend</h3> {/* Centered title */}
      <div className="flex flex-col items-center justify-center w-full h-full">
        {data && data.SpecialtyMarketing && (
          <>
            <div className="w-[30vh] h-[30vh]"> {/* Adjusted the pie chart size */}
              <PieGraph
                data={data.SpecialtyMarketing}
                graphOptions={{}}
              />
            </div>

            <Explicit data={data.SpecialtyMarketing} />
          </>
        )}
      </div>
    </div>

    {/* Content Creation Spend Box */}
    <div className="p-3 border-[1px] border-solid border-light w-[48%] h-[50vh] flex flex-col items-center justify-center"> {/* Fixed height of 50% viewport height */}
      <h3 className="text-center">Content Creation Spend</h3> {/* Centered title */}
      <div className="flex flex-col items-center justify-center w-full h-full">
        {data && data.ContentCreation && (
          <>
            <div className="w-[30vh] h-[30vh]"> {/* Adjusted the pie chart size */}
              <PieGraph
                data={data.ContentCreation}
                graphOptions={{}}
              />
            </div>
            <Explicit data={data.ContentCreation} />
          </>
        )}
      </div>
    </div>
  </div>
</div>


	);
}
