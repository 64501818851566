import {
	FunnelChart,
	Tooltip,
	Funnel,
	LabelList,
	ResponsiveContainer,
} from "recharts";
import { SingleCategoricalData } from "../../../types/types";
import { useEffect, useState } from "react";

const COLORS = ["#00274d", "#003366", "#004080", "#004d99", "#0059b3"];

// const CustomTooltip = (props: any) => {
// 	const { payload, label } = props;
// 	// const { active, payload, label } = props;
// 	// if (active && payload && payload.length) {
// 	console.log(payload);
// 	const { x, y, width, height, value } = props;
// 	const radius = 10;
// 	const dropoff = payload.dropoff;
// 	return dropoff ? (
// 		<div className="p-3 text-dark bg-light">
// 			<p>{`% fall-off from ${payload.previous_key}: `}</p>
// 			<p>{`${dropoff.toFixed(2)} %`}</p>
// 		</div>
// 	) : (
// 		<div className="p-3 text-dark bg-light"></div>
// 	);
// 	// }

// 	return null;
// };

const mapTagToLabel: any = {
	ticket: "Total Film Tickets",
	"film-started": "Film Started",
	"film-finished": "Film Finished",
};

const Stat = (props: any) => {
	const { value, x, y, width, height, number, label } = props;

	const statx = x + width / 2;
	const staty = y + height / 2;

	return (
		<g transform={`translate(${statx},${staty})`} fill="black">
			<text
				x="0"
				y="0"
				dy={0}
				dx={0}
				fill="white"
				transform="rotate(-0)"
				textAnchor="middle"
				style={{ fontSize: `${(width / 24) + (height / 5)}px` }}
			>
				{value.toLocaleString()}
			</text>
		</g>
	);
};

const Label = (props: any) => {
	const { value, x, y, width, height, number, label } = props;

	const labelx = x + width / 2;
	const labely = y + (3 * height) / 4;

	return (
		<g transform={`translate(${labelx},${labely})`} fill="black">
			<text
				x=""
				y="0"
				dy={0}
				dx={0}
				fill="white"
				transform="rotate(-0)"
				textAnchor="middle"
				// style={{ fontSize: fontSelector(0.8, true) }}
			>
				{value}
			</text>
		</g>
	);
};

// <g classNp>{`% fall-off from ${element.previous_key}: `}</p>
//         <p>{`${element.dropoff.toFixed(2)} %`}</p>
//     </g>ame="p-3 text-dark bg-light">
// };

export default function FunnelGraph({
	data,
	hover,
}: {
	data: SingleCategoricalData[] & { colour?: string };
	hover: SingleCategoricalData[];
}) {
	// console.log(data);
	// let displayData = data.map(
	// 	(element: SingleCategoricalData, index: number) => {
	// 		element.key = element.key + `: ${element.value}`;
	// 		element.fill = COLORS[index];
	// 		return element;
	// 	}
	// );
	// console.log(displayData);

	const compareFn = (a: { value: number }, b: { value: number }) => {
		if (a.value > b.value) {
			return -1;
		} else {
			return 1;
		}
	};

	let fixedValue = 100; // Set the fixed value you want to add to each object

	data.forEach((item) => {
		item.fixedValue = fixedValue;
		fixedValue = fixedValue * 0.8;
	});

	data.sort(compareFn);
	for (let i in data) {
		data[i].fill = COLORS[i];
	}

	return (
		<ResponsiveContainer width="100%" height="100%">
			<FunnelChart
				{...{
					overflow: "visible",
				}}
			>
				<Tooltip content={<CustomTooltip externalData={hover} />} />
				<Funnel
					dataKey="fixedValue"
					data={data}
					isAnimationActive
					// @ts-ignore
					isRectangle={true}
				>
					{/* <LabelList
						position="center"
						fill="#000"
						stroke="white"
						dataKey="key"
						offset={50}
					/> */}
					<LabelList
						position="center"
						fill="#000"
						stroke="white"
						dataKey="key"
						offset={50}
						content={Label}
					/>
					<LabelList
						position="center"
						fill="#000"
						stroke="white"
						dataKey="value"
						offset={50}
						content={Stat}
					/>
				</Funnel>
			</FunnelChart>
		</ResponsiveContainer>
	);
}

// Define the types for the CustomTooltip props
interface CustomTooltipProps {
	active?: boolean;
	payload?: any[];
	externalData: SingleCategoricalData[];
}

// Custom Tooltip Component
const CustomTooltip: React.FC<CustomTooltipProps> = ({
	active,
	payload,
	externalData,
}) => {
	if (active && payload && payload.length) {
		const { key, value } = payload[0].payload;
		const dataBreakdown = externalData.map(
            (item: SingleCategoricalData) => ({
                ...item,
                percent: Math.round(item.value * 100),
            })
        );
		console.log(externalData);
		// Only show tooltip if the key is "Total Film Tickets"
		if (key === "Total Film Tickets") {
			return (
				<div
					className="custom-tooltip bg-shade2"
					style={{
						padding: "10px",
						maxWidth: "300px", // Allow content to wrap if needed
						border: "1px solid #ccc",
						wordWrap: "break-word",
						whiteSpace: "normal", // Enable wrapping for long text
					}}
				>
					<p className="font-bold">Total Film Tickets</p>{" "}
					{/* Convert BigInt to string */}
					{dataBreakdown.map((item2: SingleCategoricalData) => {
						return (
							<p>{`${
								item2.key
							}: ${item2.value.toLocaleString()}`}</p>
						);
					})}
				</div>
			);
		}
	}

	return null;
};
